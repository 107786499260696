var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DistributionRules" }, [
    !["SHJ", "DBJ", "TQJ", "LTJ"].includes(_vm.form.vacationTypeCode)
      ? _c(
          "div",
          [
            _c("BlockTitle", {
              staticClass: "msg-title",
              attrs: { title: "发放规则", "is-bold": true },
            }),
            _c("AutomaticRules", { attrs: { form: _vm.form } }),
            !_vm.form.vacationTypeCode !== "TXJ"
              ? _c(
                  "a-form-model-item",
                  { attrs: { label: "发放时间" } },
                  [
                    _vm.form.vacationTypeCode === "NXJ"
                      ? _c(
                          "a-radio-group",
                          {
                            model: {
                              value: _vm.form.vacationGrantTimeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vacationGrantTimeType", $$v)
                              },
                              expression: "form.vacationGrantTimeType",
                            },
                          },
                          [
                            _c(
                              "a-radio",
                              { style: _vm.radioStyle, attrs: { value: "1" } },
                              [
                                _vm._v(" 以入职时间为开始时间，满"),
                                _c("CbNumber", {
                                  staticClass: "number-input",
                                  attrs: {
                                    "input-width": "90",
                                    suffix: "个月",
                                    "decimal-separator": false,
                                  },
                                  model: {
                                    value: _vm.form.grantWorkMonth,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "grantWorkMonth", $$v)
                                    },
                                    expression: "form.grantWorkMonth",
                                  },
                                }),
                                _vm._v("开始享有当年的年假 "),
                              ],
                              1
                            ),
                            _c(
                              "a-radio",
                              { style: _vm.radioStyle, attrs: { value: "2" } },
                              [
                                _vm._v(" 每年"),
                                _c("a-date-picker", {
                                  staticClass: "large-input",
                                  attrs: {
                                    format: "MM-DD",
                                    "value-format": "MM-DD",
                                    mode: "date",
                                  },
                                  model: {
                                    value: _vm.form.grantDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "grantDate", $$v)
                                    },
                                    expression: "form.grantDate",
                                  },
                                }),
                                _c("span", [_vm._v("开始享受当年的年假")]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c("span", [_vm._v("员工申请即可享受")]),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "a-form-model-item",
              { attrs: { label: "不享受假期" } },
              [
                _c("a-checkbox-group", {
                  attrs: { options: _vm.vacationStaffList },
                  model: {
                    value: _vm.form.vacationLimitStaffType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vacationLimitStaffType", $$v)
                    },
                    expression: "form.vacationLimitStaffType",
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }